<template>
  <v-navigation-drawer
    :value="show"
    @input="toggleShow"
    class="sidebar-six"
    dark
    :style="{
      backgroundColor: appBarColor,
    }"
    height="100%"
    width="280px"
    :mobile-breakpoint="960"
    :floating="true"
    app
    style="z-index: 50"
  >
    <div
      class="ps d-flex flex-column"
      style="height: 100%"
      :style="{
        backgroundColor: appBarColor,
      }"
    >
      <div
        style="position: sticky; top: 0px; z-index: 1"
        :style="{
          backgroundColor: appBarColor,
        }"
      >
        <div class="pa-5 top-toolbar text-center">
          <router-link to="/admin/organization">
            <img
              alt="logo"
              height="40"
              class="unselectable non-draggable"
              src="@/assets/images/logo/logo-light.png"
            />
          </router-link>
        </div>

        <div class="mb-4">
          <v-menu
            v-if="!!selectedOrganization"
            v-model="showMenu"
            rounded="0 b-lg"
            offset-y
            eager
            z-index="50"
            transition="slide-y-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <div
                v-bind="attrs"
                v-on="on"
                class="pa-3 mx-4 rounded-lg white--text d-flex space-between"
                :style="{
                  backgroundColor: adjustBrightness(appBarColor, 20),
                }"
                :class="{
                  'rounded-b-0': showMenu,
                }"
              >
                <div class="flex-1 d-flex align-center gap-2">
                  <base-avatar
                    :src="selectedOrganization.photo"
                    :seed="selectedOrganization.id"
                    :size="35"
                    avatarStyle="shapes"
                    color="secondary lighten-2"
                  />
                  <div>
                    <p
                      class="subtitle-1 mb-0 text-capitalize font-weight-black"
                      style="line-height: 1.3rem"
                    >
                      {{ selectedOrganization.name }}
                    </p>
                    <p
                      v-if="selectedOrganization.OrganizationMember.length"
                      class="text-subtitle-2 mb-0 grey--text text--lighten-1"
                    >
                      <!-- {{ selectedOrganization.email }} -->
                    </p>
                    <p
                      v-else-if="
                        selectedOrganization.GrantingOrganizations.length
                      "
                      class="text-subtitle-2 mb-0 grey--text text--lighten-1"
                    >
                      Via
                      <i class="font-weight-bold">{{
                        selectedOrganization.GrantingOrganizations[0]
                          .accessingOrganization.name
                      }}</i>
                    </p>
                  </div>
                </div>
                <v-icon right>mdi-chevron-down</v-icon>
              </div>
            </template>

            <v-list
              :style="{
                backgroundColor: adjustBrightness(appBarColor, 20),
              }"
              color="text--white py-0"
            >
              <v-text-field
                @click.stop
                v-if="orgs.length > 5 || search"
                ref="search"
                placeholder="Pesquisar"
                outlined
                dark
                class="mx-4 pt-4 mb-3"
                dense
                hide-details
                autofocus
                @keydown.enter.stop="selectFirstOrg"
                v-model="search"
              />
              <span v-if="orgs.length == 0 && !!search" class="mx-4 py-4">
                Nenhuma organização encontrada
              </span>
              <vue-perfect-scrollbar
                :settings="{ suppressScrollX: true, wheelPropagation: false }"
                class="ps flex-grow-1"
                style="max-height: 300px; max-width: 248px"
              >
                <v-list-item
                  dark
                  v-for="(item, index) in orgs"
                  :key="index"
                  @click="selectOrg(item.id)"
                >
                  <!-- <v-list-item-content> -->
                  <div
                    class="d-flex align-center gap-3"
                    style="max-width: 100%"
                  >
                    <base-avatar
                      :src="item.photo"
                      :seed="item.id"
                      :size="25"
                      avatarStyle="shapes"
                      color="secondary lighten-2"
                    />
                    <span class="text-truncate text-16">
                      {{ item.name }}
                    </span>
                  </div>
                  <!-- </v-list-item-content> -->
                </v-list-item>
              </vue-perfect-scrollbar>
              <v-divider v-if="config.sudoMode" class="my-1 grey"></v-divider>
              <v-list-item v-if="config.sudoMode" dark @click="createOrg">
                <v-list-item-title> Criar Organização </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <span
            v-if="config.opMode"
            class="text-center text-overline lh-1 d-block grey--text mt-2"
            style="margin-bottom: -12px"
          >
            #{{ selectedOrganization.id | shortId }}
          </span>
        </div>
        <v-divider class=""></v-divider>
      </div>

      <vue-perfect-scrollbar
        :settings="{ suppressScrollX: true, wheelPropagation: false }"
        class="ps flex-grow-1"
        style="height: 100%"
      >
        <v-list class="py-0 mx-4" dense>
          <template v-for="(item, i) in computedItems">
            <div :key="item.subheader" v-if="item.subheader">
              <v-subheader>
                {{ item.subheader }}
              </v-subheader>

              <v-divider></v-divider>
            </div>

            <base-item-group-two
              v-if="item.children"
              :key="`group-${i}`"
              :item="item"
            >
            </base-item-group-two>

            <base-item-two
              v-else-if="!item.subheader"
              :key="`item-${i}`"
              :item="item"
            />
          </template>
        </v-list>
      </vue-perfect-scrollbar>

      <div
        v-if="hasPermission([32, 1024])"
        class="px-6 py-4 pb-7 pt-0"
        style="position: sticky; bottom: 0px"
        :style="{
          backgroundColor: appBarColor,
        }"
      >
        <v-divider class="mb-4"></v-divider>
        <!-- <div class="mx-6  white--text">
        <p class="subtitle-1 mb-0 text-capitalize">TicketMe</p>
        <p class="text-subtitle-2 mb-0 grey--text">Your tier: Premium</p>
      </div> -->
        <div>
          <v-btn
            block
            large
            class="text-capitalize"
            color="primary"
            :to="{ name: 'admin.sell' }"
            id="nav-sell-btn"
          >
            <span style="color: black">Vender!</span>
          </v-btn>
        </div>
      </div>
    </div>
  </v-navigation-drawer>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { appBarColor } from "@/themeConfig.js";
import menu from "@/data/admin-menu.js";

export default {
  data() {
    return {
      selectedItem: 0,
      group: null,
      showMenu: false,
      appBarColor,
      search: "",
    };
  },
  computed: {
    ...mapGetters(["getThemeMode"]),
    ...mapGetters("organization", ["organizations", "selectedOrganization"]),
    ...mapGetters("organization", { orgHasFlag: "hasFlag" }),

    ...mapGetters("auth", ["hasPermission", "hasFlag", "config"]),
    show() {
      let sidebar = this.getThemeMode.verticalSidebarDrawer;
      let selectedOrganization = this.selectedOrganization;
      return sidebar && !!selectedOrganization;
    },
    orgs() {
      return this.organizations.filter((org) => {
        if (
          !!this.selectedOrganization &&
          org.id === this.selectedOrganization.id
        )
          return false;
        if (!this.search) return true;

        const search = this.textNormalizer(this.search);
        return this.textNormalizer(org.name).includes(search);
      });
    },
    getMiniVariant() {
      return this.miniVariant;
    },
    computedItems() {
      const result = [];

      const selectedOrganization = this.selectedOrganization;
      if (!selectedOrganization) return result;

      const user_permissions = this.$store.getters["auth/userPermissions"].find(
        (p) => p.organizationId === selectedOrganization.id
      );
      if (!user_permissions) return result;

      const menuFiltered = menu.map((item) => {
        var children = item.children.filter((child) => {
          if (child.flags) {
            const hasFlag = Object.entries(child.flags).every(
              ([key, value]) =>
                this.hasFlag(key, value) || this.orgHasFlag(key, value)
            );
            if (!hasFlag) return false;
          }
          if (user_permissions.owner) return true;

          if (!child.permission) return true;
          return this.hasPermission(child.permission);
        });
        return {
          ...item,
          children,
        };
      });

      menuFiltered.forEach((menu) => {
        if (!menu.children.length) return;
        menu.children.forEach((c, i) => {
          result.push({
            ...c,
            divider: i == 0 ? menu.divider : undefined,
          });
        });
      });

      return result;
    },
  },
  methods: {
    ...mapActions(["changeVerticalSidebarDrawer"]),
    ...mapActions("organization", [
      "selectOrganization",
      "getAllOrganizations",
    ]),
    textNormalizer(text) {
      return text
        .trim()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .replace(/([^\w]+|\s+)/g, "-")
        .replace(/\-\-+/g, "-")
        .replace(/(^-+|-+$)/, "")
        .toLowerCase();
    },
    adjustBrightness(hex, amount) {
      hex = hex.replace(/^#/, "");
      let r = parseInt(hex.substring(0, 2), 16) + amount;
      let g = parseInt(hex.substring(2, 4), 16) + amount;
      let b = parseInt(hex.substring(4, 6), 16) + amount;
      r = Math.max(Math.min(255, r), 0).toString(16);
      g = Math.max(Math.min(255, g), 0).toString(16);
      b = Math.max(Math.min(255, b), 0).toString(16);
      return `#${r.padStart(2, "0")}${g.padStart(2, "0")}${b.padStart(2, "0")}`;
    },
    toggleShow(v) {
      if (!v) this.changeVerticalSidebarDrawer(v);
    },
    async getOrgs() {
      await this.getAllOrganizations();
    },
    selectOrg(id) {
      this.selectOrganization(id);
    },
    createOrg() {
      this.$router.push("/admin/organization/create");
    },
    toggleSidebar() {
      this.changeVerticalSidebarMini();
      this.expandOnHover = !this.expandOnHover;
    },
    selectFirstOrg() {
      if (this.orgs.length) {
        this.showMenu = false;
        this.selectOrg(this.orgs[0].id);
      }
    },
  },
  watch: {
    async showMenu(v) {
      if (!v) this.search = "";
    },
  },
  async beforeMount() {
    await this.getOrgs();
  },
};
</script>
<style lang="scss">
.bg-sidebar-six {
  // background-color: #303133;
}
.theme--dark .v-navigation-drawer {
  .bg-sidebar-six {
    // background-color: #303133;
  }
}
.sidebar-six {
  .v-list-item__icon {
    margin-right: 8px !important;
    // margin-bottom: 0;
    // margin-top: 0;
  }

  .v-list-item--link:before {
    border-radius: 8px;
  }
}
</style>
